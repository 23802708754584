<template>
  <div class="InstrumentIDManage content_body">
    <!-- 筛选 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="22">
          <el-form
            :inline="true"
            size="small"
            class="demo-form-inline"
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="品牌名称">
              <el-input placeholder="输入品牌名称搜索" v-model="searchName"></el-input>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select v-model="Active" placeholder="请选择" @change="handleSearch">
                <el-option
                  v-for="item in ActiveList"
                  :key="item.Name"
                  :label="item.Name"
                  :value="item.Value"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" size="small" @click="handleSearch">搜索</el-button>
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="2" class="text_right">
          <el-button type="primary" size="small" @click="addInstrument">新增仪器品牌</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="table-box">
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="light" style="width: 100%">
        <!-- <el-table-column label="品牌编号" prop="BrandID"></el-table-column> -->
        <el-table-column label="名称" prop="Name"></el-table-column>
        <el-table-column label="联系人" prop="Contacts"></el-table-column>
        <el-table-column label="电话号码" prop="TEL"></el-table-column>
        <el-table-column label="地址" prop="Address"></el-table-column>
        <el-table-column label="有效性" prop="active">
          <template slot-scope="scope">{{scope.row.Active?'有效':'无效'}}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" @click="editor(scope.row)" size="small">编辑</el-button>
            <el-button type="danger" @click="deleter(scope.row)" v-if="isDelete" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pad_15 text_right">
        <el-pagination
          background
          v-if="paginations.total > 0"
          @current-change="handleCurrentChange"
          :current-page.sync="paginations.page"
          :page-size="paginations.page_size"
          :layout="paginations.layout"
          :total="paginations.total"
        ></el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      :title="dialogtype==1?'新增':'编辑'"
      :visible.sync="dialogVisible"
      width="800px"
      destroy-on-close
      append-to-body
      :before-close="closeDialog"
    >
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" size="small">
          <el-row>
            <el-col :span="12">
              <el-form-item label="联系人" prop="Contacts">
                <el-input
                  clearable
                  v-model="ruleForm.Contacts"
                  placeholder="请输入联系人名称"
                  class="width_220"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="名称" prop="Name">
                <el-input clearable v-model="ruleForm.Name" placeholder="请输入名称" class="width_220"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="电话" prop="TEL">
                <el-input clearable v-model="ruleForm.TEL" placeholder="请输入电话" class="width_220"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="地址" prop="Address">
                <el-input clearable v-model="ruleForm.Address" placeholder="请填写地址" class="width_220"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <w> -->
          <el-row v-if="dialogtype==2">
            <el-col :span="12">
              <el-form-item label="品牌ID" prop="BrandID">
                <el-input clearable v-model="ruleForm.BrandID" placeholder="请输入品牌ID" class="width_220"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="有效性" prop="Active">
               <el-radio v-model="ruleForm.Active" :label="true">有效</el-radio>
                <el-radio v-model="ruleForm.Active" :label="false">无效</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" v-prevent-click>取 消</el-button>
        <el-button type="primary" size="small" v-prevent-click @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import permission from "@/components/js/permission.js";
import API from "../../../api/CRM/Instrument/InstrumentBrand";
export default {
  name: "Brand",
  /**  引入的组件  */
  components: {},
  beforeCreate(to, from, next) {
    next((vm) => {
      vm.isDelete = permission.permission(to.meta.Permission, "仪器品牌");
    });
  },
  /**  Vue 实例的数据对象**/
  data() {
    return {
      Active: null,
      ActiveList: [
        { Name: "有效", Value: true },
        { Name: "无效", Value: false },
        { Name: "全部", Value: null },
      ],
      isDelete: false,
      dialogtype: 1,
      dialogVisible: false,
      searchName: "",
      tableData: [],
      paginations: {
        page: 1,
        total: 1,
        page_size: 10,
        layout: "total, prev, pager, next,jumper",
      },
      ruleForm: {
        Active:true
      },
      rules: {
        BrandID: [
          { required: true, message: "请输入品牌ID", trigger: "blur" },
        ],
        Name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        Active:[{ required: true, trigger: "blur" }]
      },
    };
  },
  /**计算属性  */
  computed: {},
  /**  方法集合  */
  methods: {
    // 展示列表
    handleSearch() {
      var params = {
        PageNum: this.paginations.page, 
        Name: this.searchName,
        Active: this.Active,
      };
      API.skinBrand(params).then((res) => {
        if (res.StateCode == 200) {
          this.tableData = res.List;
          this.paginations.total = res.Total;
        }else{
            this.$message.error(res.message)
        }
      });
    },
    // 添加弹窗
    addInstrument() {
      this.dialogtype = 1;
      this.dialogVisible = true;
    },
    //编辑
    editor(row) {
      const { Contacts, Name, TEL, Address,BrandID,Active } = row;
      this.ruleForm = {
        Name,
        Contacts,
        TEL,
        Address,
        BrandID,
        Active
      };
      this.dialogtype = 2;
      this.dialogVisible = true;
    },
    // 关闭弹窗
    closeDialog(done) {
      console.log(123);
      this.ruleForm = {
        Active:true
      };
      done();
    },
    // 删除
    deleter(row) {
      this.$confirm("此操作将永久删除此检测项目, 是否继续?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "已删除",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //  确认
    submitForm(formName) {
      var that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          if (that.dialogtype == 1) {
            that.createManage();
          } else {
            that.updateManage();
          }
        }
      });
    },
    // 新增
    createManage() {
      var that = this;
      const { Name, Contacts, TEL, Address } = that.ruleForm;
      var params = {
        Name,
        Contacts,
        TEL,
        Address,
      };
      API.create(params).then((res) => {
        if (res.StateCode == 200) {
          that.$message.success("新增成功!");
          that.closeDialog();
          that.handleSearch();
        }else{
          that.$message.error(res.Message)
        }
      });
    },
    // 编辑
    updateManage() {
      var that = this;
      const { Name, BrandID,Active,Address, TEL,Contacts } = that.ruleForm;
      var params = {
        Name,
        BrandID,
        Contacts,
        TEL,
        Address,
        Active
      };
      API.update(params).then((res) => {
        if (res.StateCode==200) {
           that.$message.success("已修改!");
          that.closeDialog();
          that.handleSearch();
        }else{
          that.$message.error(res.Message)
        }
        
      });
    },
    // 页码值变化
    handleCurrentChange(page) {
      var that = this;
      that.paginations.page = page;
      that.handleSearch();
    },
  },
  /** 监听数据变化   */
  watch: {},
  /** 创建实例之前执行函数   */
  beforeCreate() {},
  /**  实例创建完成之后  */
  created() {},
  /**  在挂载开始之前被调用  */
  beforeMount() {},
  /**  实例被挂载后调用  */
  mounted() {
    this.isDelete = permission.permission(
      this.$route.meta.Permission,
      "仪器品牌"
    );
    this.handleSearch();
  },
  /**  数据更新 之前 调用  */
  beforeUpdate() {},
  /** 数据更新 完成 调用   */
  updated() {},
  /**  实例销毁之前调用  */
  beforeDestroy() {},
  /**  实例销毁后调用  */
  destroyed() {},
};
</script>

<style lang="scss">
</style>
